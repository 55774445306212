import { createRouter, RouteRecordRaw, createWebHistory } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'pre-order',
    component: () => import('@/views/PreOrderView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'other',
    redirect: { name: 'pre-order' }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
