import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { createVuetify } from 'vuetify'
import { fr } from 'vuetify/locale'

import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

const vuetify = createVuetify({
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#89C37C',
          secondary: '#ff4081',
          accent: '#ff4081',
          error: '#e85858',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107'
        }
      }
    }
  },
  locale: {
    locale: 'fr',
    fallback: 'fr',
    messages: { fr }
  }
})

import './assets/styles/tailwind.css'
import './assets/styles/variables.css'
import './assets/styles/styles.scss'
import './assets/styles/fontawesome.css'

createApp(App).use(router).use(store).use(vuetify).mount('#app')
