import { createStore } from 'vuex'

export default createStore({
  state: {
    screenWidth: window.innerWidth
  },
  getters: {
    getScreenWidth: (state) => state.screenWidth
  },
  mutations: {
    SET_SCREEN_WIDTH(state, width) {
      state.screenWidth = width
    }
  },
  actions: {},
  modules: {}
})
