<template>
  <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/css?family=Lexend:300,400,500,700&display=swap"
  />
  <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
  />

  <vApp>
    <vMain>
      <routerView />
    </vMain>
  </vApp>
</template>

<script setup lang="ts">
  import { onMounted, onUnmounted } from 'vue'
  import { useStore } from 'vuex'

  onMounted(() => {
    document.title = 'Click n Zen'
  })

  const store = useStore()

  const updateScreenWidth = () => {
    store.commit('SET_SCREEN_WIDTH', window.innerWidth)
  }

  onMounted(() => {
    updateScreenWidth()
    window.addEventListener('resize', updateScreenWidth)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', updateScreenWidth)
  })
</script>
